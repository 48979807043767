/* This File is for {purpose} */

<template>
  <v-layout wrap justify-start fill-height>
    <v-flex xs12 px-2 px-sm-4 px-lg-8>
      <v-layout wrap justify-start py-8 v-if="!this.$route.params.id">
        <v-flex xs12 lg4>
          <v-layout wrap justify-start>
            <v-flex xs9>
              <span class="title-2">
                {{ $t("pages.employees.upload_csv") }}
              </span>
              <p class="subtitle-2">
                {{ $t("pages.employees.upload_csv_desc") }}
              </p>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs12 lg6 offset-2 style="height: 200px">
          <v-layout wrap justify-center fill-height pa-2>
            <v-flex
              xs12
              align-self-center
              text-center
              class="border-dotted"
              style="height: 100%"
              @dragover="dragover"
              @dragleave="dragleave"
              @drop="drop"
            >
              <input
                type="file"
                name="file"
                id="fileInput"
                class="hidden-input"
                @change="onFileChange"
                ref="file"
                accept=".csv,.xlsx"
                hidden
              />
              <v-layout wrap justify-center fill-height>
                <v-flex xs12 align-self-center>
                  <v-img
                    src="@/assets/images/file.svg"
                    height="50px"
                    contain
                    @click="$refs.file.click()"
                  ></v-img>
                  <p v-if="files" class="subtitle-2">
                    {{ files.name }} <br />
                    <span @click.prevent="files = null">
                      <v-icon color="error" size="medium">mdi-close</v-icon>
                      Clear
                    </span>
                  </p>
                  <span v-else-if="isDragging" class="subtitle-2">
                    Release to drop files here.
                  </span>
                  <span v-else class="subtitle-2">
                    {{ $t("form.select_file_upload", { type: "Excel" }) }}
                  </span>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
      <v-flex xs12 v-if="!this.$route.params.id">
        <div class="hr-sect text-capitalize">
          {{ $t("words.or") }}
        </div>
      </v-flex>
      <v-form ref="form" lazy-validation>
        <v-layout wrap justify-start py-8>
          <v-flex xs12 lg4>
            <v-layout wrap justify-start>
              <!--            <v-flex xs2 xl1>-->
              <!--              <v-avatar color="primary" size="31">-->
              <!--                <span class="avtr-txt"> 01 </span>-->
              <!--              </v-avatar>-->
              <!--            </v-flex>-->
              <v-flex xs10 xl11>
                <span class="title-2">
                  {{ $t("pages.employees.enter_employee_bsc_dtl") }}
                </span>
                <p class="subtitle-2">
                  {{ $t("messages.warning.all_mandatory") }}
                </p>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 lg6 offset-2>
            <v-layout wrap justify-start>
              <v-flex xs12 lg6 pa-lg-2 align-self-center>
                <p class="input-label">{{ $t("form.first_name") }}</p>
                <v-text-field
                  dense
                  outlined
                  v-model="first_name"
                  :rules="[RULES.REQUIRED]"
                />
              </v-flex>
              <v-flex xs12 lg6 pa-lg-2 align-self-center>
                <p class="input-label">{{ $t("form.last_name") }}</p>
                <v-text-field
                  dense
                  outlined
                  v-model="last_name"
                  :rules="[RULES.REQUIRED]"
                />
              </v-flex>
              <v-flex xs12 lg6 pt-4 pa-lg-2>
                <p class="input-label">{{ $t("form.gender") }}</p>
                <v-select
                  outlined
                  dense
                  :items="GENDERS"
                  item-value="value"
                  item-text="label"
                  v-model="gender_id"
                  :rules="[RULES.REQUIRED]"
                />
              </v-flex>
              <v-flex xs12 lg6 pt-4 pa-lg-2 align-self-start>
                <p class="input-label">{{ $t("form.email") }}</p>
                <v-text-field
                  dense
                  outlined
                  v-model="email"
                  :rules="[RULES.REQUIRED, RULES.EMAIL]"
                />
              </v-flex>
              <v-flex xs12 lg6 pt-4 pa-lg-2 align-self-start>
                <p class="input-label">{{ $t("form.mobile_number") }}</p>
                <v-text-field
                  dense
                  outlined
                  v-model="phone"
                  :rules="[RULES.REQUIRED]"
                />
              </v-flex>
              <v-flex xs12 lg6 pt-4 pa-lg-2 align-self-center>
                <p class="input-label">{{ $t("form.address") }}</p>
                <v-textarea
                  rows="4"
                  dense
                  outlined
                  v-model="address"
                  :rules="[RULES.REQUIRED]"
                />
              </v-flex>
              <v-flex xs12 lg6 pa-lg-2>
                <p class="input-label">{{ $t("form.qualification") }}</p>
                <v-select
                  outlined
                  dense
                  multiple
                  :items="qualifications"
                  item-text="name"
                  item-value="id"
                  v-model="qualification_id"
                  :rules="[RULES.REQUIRED]"
                />
              </v-flex>
              <v-flex xs12 lg6 pt-4 pa-lg-2 align-self-center>
                <p class="input-label">{{ $t("form.department") }}</p>
                <v-select
                  outlined
                  dense
                  multiple
                  :items="departments"
                  item-text="name"
                  item-value="id"
                  v-model="department_id"
                  :rules="[RULES.REQUIRED]"
                ></v-select>
              </v-flex>
              <v-flex xs12 lg6 pt-4 pa-lg-2 align-self-center>
                <p class="input-label">{{ $t("form.language") }}</p>
                <v-select
                  outlined
                  dense
                  :items="languages"
                  multiple
                  item-value="value"
                  item-text="label"
                  v-model="language_id"
                  :rules="[RULES.REQUIRED]"
                ></v-select>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-form>
    </v-flex>
    <v-footer fixed tile padless app>
      <v-flex xs12 align-self-end>
        <v-card tile height="100%">
          <v-layout wrap justify-start fill-height py-8>
            <v-flex xs12 px-8 text-right align-self-center>
              <span class="px-4">
                <AppNavButton
                  :route="{ name: 'employees' }"
                  color="primary"
                  custom-class="pa-5"
                  outlined
                >
                  <template #content>
                    <span style="color: #3278ff">
                      {{ $t("buttons.cancel") }}
                    </span>
                  </template>
                </AppNavButton>
              </span>

              <span class="px-4">
                <AppButton @submit="manageForm" custom-class="pa-5">
                  <template #content>
                    {{ $t(getButtonLabel) }}
                  </template>
                </AppButton>
              </span>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-footer>

    <SuccessConfirmation
      v-model="showSuccess"
      :title="successTitle"
      :type="successType"
      @submit="$router.go(-1)"
      @cancel="toggleSuccess"
    />
    <v-snackbar v-model="showSnackbar" :color="snackbarColor" bottom right>
      {{ message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="showSnackbar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-layout>
</template>

<script>
import { mapGetters } from "vuex";
import { RULES } from "@/libs/helper/components/rules";
import { LANGUAGES } from "@/constants/languages";
import { GENDERS } from "@/constants/genders";

export default {
  name: "AddEmployee",
  data: () => ({
    RULES,
    GENDERS,
    showSuccess: false,
    showSnackbar: false,
    snackbarColor: "error",
    message: null,
    first_name: null,
    last_name: null,
    gender_id: null,
    email: null,
    phone: null,
    address: null,
    qualification_id: null,
    department_id: null,
    language_id: null,
    isDragging: false,
    files: null,
  }),
  computed: {
    ...mapGetters({
      qualifications: "app/getQualifications",
      departments: "app/getDepartments",
    }),
    languages() {
      return LANGUAGES.map((itm) => {
        return {
          ...itm,
          label: this.$t(itm.label),
        };
      });
    },
    getButtonLabel() {
      return this.$route.params.id
        ? "buttons.save"
        : this.files
        ? "buttons.upload"
        : "buttons.add";
    },
    successTitle() {
      return this.$route.params.id
        ? "pages.employees.employee_updated"
        : "pages.employees.employee_added";
    },
    successType() {
      return this.$route.params.id ? "edit" : "employee";
    },
  },
  created() {
    if (this.$route.params.id) this.getData();
  },
  mounted() {
    const breadCrumbs = [
      {
        name: "employees",
        text: "pages.employees.title",
      },
      {
        name: this.$route.name,
        text: this.$route.params.id
          ? "pages.employees.edit_employee"
          : "pages.employees.add_employee",
      },
    ];
    this.$store.commit("app/setBreadCrumbs", breadCrumbs);
  },
  methods: {
    onFileChange() {
      const formData = new FormData();
      formData.append("file", this.$refs.file?.files?.[0]);
      let fileName = "File";
      for (const entry of formData.entries()) {
        // eslint-disable-next-line no-unused-vars
        const [name, file] = entry;
        fileName = file.name;
      }
      this.files = { file: formData, name: fileName };
    },
    dragover(e) {
      e.preventDefault();
      this.isDragging = true;
    },
    dragleave() {
      this.isDragging = false;
    },
    drop(e) {
      e.preventDefault();
      this.$refs.file.files = e.dataTransfer.files;
      this.onFileChange();
      this.isDragging = false;
    },
    toggleSuccess() {
      this.showSuccess = !this.showSuccess;
    },
    getData() {
      this.$store
        .dispatch("hospital/getEmployee", this.$route.params.id)
        .then((res) => {
          this.first_name = res?.first_name;
          this.last_name = res?.last_name;
          this.gender_id = res?.gender_id;
          this.email = res?.email;
          this.phone = res?.phone;
          this.address = res?.address;
          this.qualification_id = res?.qualifications?.map((itm) => {
            return itm.qualification_id;
          });
          this.department_id = res?.departments?.map((itm) => {
            return itm.department_id;
          });
          this.language_id = res?.languages?.map((itm) => {
            return itm.language_id;
          });
        });
    },
    manageForm() {
      if (this.files) this.uploadEmployees();
      else if (this.$route.params.id) this.updateEmployee();
      else this.addEmployee();
    },
    uploadEmployees() {
      const payload = this.files.file;
      this.$store.dispatch("hospital/uploadEmployees", payload).then((res) => {
        console.log(res);
        this.toggleSuccess();
      });
    },
    updateEmployee() {
      if (!this.$refs.form.validate()) return;
      const data = {
        id: this.$route.params.id,
        first_name: this.first_name,
        last_name: this.last_name,
        gender_id: this.gender_id,
        email: this.email,
        phone: this.phone,
        address: this.address,
        qualification_id: this.qualification_id,
        department_id: this.department_id,
        language_id: this.language_id,
      };
      this.$store
        .dispatch("hospital/editEmployee", data)
        .then(() => {
          this.toggleSuccess();
        })
        .catch((err) => {
          this.message = err.data.message;
          this.snackbarColor = "error";
          this.showSnackbar = true;
        });
    },
    addEmployee() {
      if (!this.$refs.form.validate()) return;
      const data = {
        first_name: this.first_name,
        last_name: this.last_name,
        gender_id: this.gender_id,
        email: this.email,
        phone: this.phone,
        address: this.address,
        qualification_id: this.qualification_id,
        department_id: this.department_id,
        language_id: this.language_id,
      };
      this.$store
        .dispatch("hospital/addEmployee", data)
        .then(() => {
          this.$refs.form.reset();
          this.toggleSuccess();
        })
        .catch((err) => {
          this.message = err.data.message;
          this.snackbarColor = "error";
          this.showSnackbar = true;
        });
    },
  },
};
</script>

<style scoped>
.v-text-field--outlined >>> fieldset {
  border-color: #c0cbe0 !important;
}

.v-text-field--outlined::v-deep input::placeholder,
.v-text-field--outlined::v-deep textarea::placeholder {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.02em;
  color: #9fadc8;
}

.v-input ::v-deep input,
.v-input ::v-deep textarea,
.v-input ::v-deep .v-select__selection {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.02em;
  color: #081734;
}

.v-input ::v-deep label {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.02em;
  color: #081734;
}
</style>

<style>
.border-dotted {
  border: 2px dashed #3278ff;
  border-radius: 7px;
}
</style>
