var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-layout",
    { attrs: { wrap: "", "justify-start": "", "fill-height": "" } },
    [
      _c(
        "v-flex",
        { attrs: { xs12: "", "px-2": "", "px-sm-4": "", "px-lg-8": "" } },
        [
          !this.$route.params.id
            ? _c(
                "v-layout",
                { attrs: { wrap: "", "justify-start": "", "py-8": "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { xs12: "", lg4: "" } },
                    [
                      _c(
                        "v-layout",
                        { attrs: { wrap: "", "justify-start": "" } },
                        [
                          _c("v-flex", { attrs: { xs9: "" } }, [
                            _c("span", { staticClass: "title-2" }, [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("pages.employees.upload_csv")) +
                                  " "
                              ),
                            ]),
                            _c("p", { staticClass: "subtitle-2" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("pages.employees.upload_csv_desc")
                                  ) +
                                  " "
                              ),
                            ]),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    {
                      staticStyle: { height: "200px" },
                      attrs: { xs12: "", lg6: "", "offset-2": "" },
                    },
                    [
                      _c(
                        "v-layout",
                        {
                          attrs: {
                            wrap: "",
                            "justify-center": "",
                            "fill-height": "",
                            "pa-2": "",
                          },
                        },
                        [
                          _c(
                            "v-flex",
                            {
                              staticClass: "border-dotted",
                              staticStyle: { height: "100%" },
                              attrs: {
                                xs12: "",
                                "align-self-center": "",
                                "text-center": "",
                              },
                              on: {
                                dragover: _vm.dragover,
                                dragleave: _vm.dragleave,
                                drop: _vm.drop,
                              },
                            },
                            [
                              _c("input", {
                                ref: "file",
                                staticClass: "hidden-input",
                                attrs: {
                                  type: "file",
                                  name: "file",
                                  id: "fileInput",
                                  accept: ".csv,.xlsx",
                                  hidden: "",
                                },
                                on: { change: _vm.onFileChange },
                              }),
                              _c(
                                "v-layout",
                                {
                                  attrs: {
                                    wrap: "",
                                    "justify-center": "",
                                    "fill-height": "",
                                  },
                                },
                                [
                                  _c(
                                    "v-flex",
                                    {
                                      attrs: {
                                        xs12: "",
                                        "align-self-center": "",
                                      },
                                    },
                                    [
                                      _c("v-img", {
                                        attrs: {
                                          src: require("@/assets/images/file.svg"),
                                          height: "50px",
                                          contain: "",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.$refs.file.click()
                                          },
                                        },
                                      }),
                                      _vm.files
                                        ? _c(
                                            "p",
                                            { staticClass: "subtitle-2" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.files.name) +
                                                  " "
                                              ),
                                              _c("br"),
                                              _c(
                                                "span",
                                                {
                                                  on: {
                                                    click: function ($event) {
                                                      $event.preventDefault()
                                                      _vm.files = null
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: {
                                                        color: "error",
                                                        size: "medium",
                                                      },
                                                    },
                                                    [_vm._v("mdi-close")]
                                                  ),
                                                  _vm._v(" Clear "),
                                                ],
                                                1
                                              ),
                                            ]
                                          )
                                        : _vm.isDragging
                                        ? _c(
                                            "span",
                                            { staticClass: "subtitle-2" },
                                            [
                                              _vm._v(
                                                " Release to drop files here. "
                                              ),
                                            ]
                                          )
                                        : _c(
                                            "span",
                                            { staticClass: "subtitle-2" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "form.select_file_upload",
                                                      { type: "Excel" }
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          !this.$route.params.id
            ? _c("v-flex", { attrs: { xs12: "" } }, [
                _c("div", { staticClass: "hr-sect text-capitalize" }, [
                  _vm._v(" " + _vm._s(_vm.$t("words.or")) + " "),
                ]),
              ])
            : _vm._e(),
          _c(
            "v-form",
            { ref: "form", attrs: { "lazy-validation": "" } },
            [
              _c(
                "v-layout",
                { attrs: { wrap: "", "justify-start": "", "py-8": "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { xs12: "", lg4: "" } },
                    [
                      _c(
                        "v-layout",
                        { attrs: { wrap: "", "justify-start": "" } },
                        [
                          _c("v-flex", { attrs: { xs10: "", xl11: "" } }, [
                            _c("span", { staticClass: "title-2" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "pages.employees.enter_employee_bsc_dtl"
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                            _c("p", { staticClass: "subtitle-2" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("messages.warning.all_mandatory")
                                  ) +
                                  " "
                              ),
                            ]),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "", lg6: "", "offset-2": "" } },
                    [
                      _c(
                        "v-layout",
                        { attrs: { wrap: "", "justify-start": "" } },
                        [
                          _c(
                            "v-flex",
                            {
                              attrs: {
                                xs12: "",
                                lg6: "",
                                "pa-lg-2": "",
                                "align-self-center": "",
                              },
                            },
                            [
                              _c("p", { staticClass: "input-label" }, [
                                _vm._v(_vm._s(_vm.$t("form.first_name"))),
                              ]),
                              _c("v-text-field", {
                                attrs: {
                                  dense: "",
                                  outlined: "",
                                  rules: [_vm.RULES.REQUIRED],
                                },
                                model: {
                                  value: _vm.first_name,
                                  callback: function ($$v) {
                                    _vm.first_name = $$v
                                  },
                                  expression: "first_name",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            {
                              attrs: {
                                xs12: "",
                                lg6: "",
                                "pa-lg-2": "",
                                "align-self-center": "",
                              },
                            },
                            [
                              _c("p", { staticClass: "input-label" }, [
                                _vm._v(_vm._s(_vm.$t("form.last_name"))),
                              ]),
                              _c("v-text-field", {
                                attrs: {
                                  dense: "",
                                  outlined: "",
                                  rules: [_vm.RULES.REQUIRED],
                                },
                                model: {
                                  value: _vm.last_name,
                                  callback: function ($$v) {
                                    _vm.last_name = $$v
                                  },
                                  expression: "last_name",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            {
                              attrs: {
                                xs12: "",
                                lg6: "",
                                "pt-4": "",
                                "pa-lg-2": "",
                              },
                            },
                            [
                              _c("p", { staticClass: "input-label" }, [
                                _vm._v(_vm._s(_vm.$t("form.gender"))),
                              ]),
                              _c("v-select", {
                                attrs: {
                                  outlined: "",
                                  dense: "",
                                  items: _vm.GENDERS,
                                  "item-value": "value",
                                  "item-text": "label",
                                  rules: [_vm.RULES.REQUIRED],
                                },
                                model: {
                                  value: _vm.gender_id,
                                  callback: function ($$v) {
                                    _vm.gender_id = $$v
                                  },
                                  expression: "gender_id",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            {
                              attrs: {
                                xs12: "",
                                lg6: "",
                                "pt-4": "",
                                "pa-lg-2": "",
                                "align-self-start": "",
                              },
                            },
                            [
                              _c("p", { staticClass: "input-label" }, [
                                _vm._v(_vm._s(_vm.$t("form.email"))),
                              ]),
                              _c("v-text-field", {
                                attrs: {
                                  dense: "",
                                  outlined: "",
                                  rules: [_vm.RULES.REQUIRED, _vm.RULES.EMAIL],
                                },
                                model: {
                                  value: _vm.email,
                                  callback: function ($$v) {
                                    _vm.email = $$v
                                  },
                                  expression: "email",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            {
                              attrs: {
                                xs12: "",
                                lg6: "",
                                "pt-4": "",
                                "pa-lg-2": "",
                                "align-self-start": "",
                              },
                            },
                            [
                              _c("p", { staticClass: "input-label" }, [
                                _vm._v(_vm._s(_vm.$t("form.mobile_number"))),
                              ]),
                              _c("v-text-field", {
                                attrs: {
                                  dense: "",
                                  outlined: "",
                                  rules: [_vm.RULES.REQUIRED],
                                },
                                model: {
                                  value: _vm.phone,
                                  callback: function ($$v) {
                                    _vm.phone = $$v
                                  },
                                  expression: "phone",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            {
                              attrs: {
                                xs12: "",
                                lg6: "",
                                "pt-4": "",
                                "pa-lg-2": "",
                                "align-self-center": "",
                              },
                            },
                            [
                              _c("p", { staticClass: "input-label" }, [
                                _vm._v(_vm._s(_vm.$t("form.address"))),
                              ]),
                              _c("v-textarea", {
                                attrs: {
                                  rows: "4",
                                  dense: "",
                                  outlined: "",
                                  rules: [_vm.RULES.REQUIRED],
                                },
                                model: {
                                  value: _vm.address,
                                  callback: function ($$v) {
                                    _vm.address = $$v
                                  },
                                  expression: "address",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs12: "", lg6: "", "pa-lg-2": "" } },
                            [
                              _c("p", { staticClass: "input-label" }, [
                                _vm._v(_vm._s(_vm.$t("form.qualification"))),
                              ]),
                              _c("v-select", {
                                attrs: {
                                  outlined: "",
                                  dense: "",
                                  multiple: "",
                                  items: _vm.qualifications,
                                  "item-text": "name",
                                  "item-value": "id",
                                  rules: [_vm.RULES.REQUIRED],
                                },
                                model: {
                                  value: _vm.qualification_id,
                                  callback: function ($$v) {
                                    _vm.qualification_id = $$v
                                  },
                                  expression: "qualification_id",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            {
                              attrs: {
                                xs12: "",
                                lg6: "",
                                "pt-4": "",
                                "pa-lg-2": "",
                                "align-self-center": "",
                              },
                            },
                            [
                              _c("p", { staticClass: "input-label" }, [
                                _vm._v(_vm._s(_vm.$t("form.department"))),
                              ]),
                              _c("v-select", {
                                attrs: {
                                  outlined: "",
                                  dense: "",
                                  multiple: "",
                                  items: _vm.departments,
                                  "item-text": "name",
                                  "item-value": "id",
                                  rules: [_vm.RULES.REQUIRED],
                                },
                                model: {
                                  value: _vm.department_id,
                                  callback: function ($$v) {
                                    _vm.department_id = $$v
                                  },
                                  expression: "department_id",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            {
                              attrs: {
                                xs12: "",
                                lg6: "",
                                "pt-4": "",
                                "pa-lg-2": "",
                                "align-self-center": "",
                              },
                            },
                            [
                              _c("p", { staticClass: "input-label" }, [
                                _vm._v(_vm._s(_vm.$t("form.language"))),
                              ]),
                              _c("v-select", {
                                attrs: {
                                  outlined: "",
                                  dense: "",
                                  items: _vm.languages,
                                  multiple: "",
                                  "item-value": "value",
                                  "item-text": "label",
                                  rules: [_vm.RULES.REQUIRED],
                                },
                                model: {
                                  value: _vm.language_id,
                                  callback: function ($$v) {
                                    _vm.language_id = $$v
                                  },
                                  expression: "language_id",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-footer",
        { attrs: { fixed: "", tile: "", padless: "", app: "" } },
        [
          _c(
            "v-flex",
            { attrs: { xs12: "", "align-self-end": "" } },
            [
              _c(
                "v-card",
                { attrs: { tile: "", height: "100%" } },
                [
                  _c(
                    "v-layout",
                    {
                      attrs: {
                        wrap: "",
                        "justify-start": "",
                        "fill-height": "",
                        "py-8": "",
                      },
                    },
                    [
                      _c(
                        "v-flex",
                        {
                          attrs: {
                            xs12: "",
                            "px-8": "",
                            "text-right": "",
                            "align-self-center": "",
                          },
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "px-4" },
                            [
                              _c("AppNavButton", {
                                attrs: {
                                  route: { name: "employees" },
                                  color: "primary",
                                  "custom-class": "pa-5",
                                  outlined: "",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "content",
                                    fn: function () {
                                      return [
                                        _c(
                                          "span",
                                          { staticStyle: { color: "#3278ff" } },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("buttons.cancel")
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                          _c(
                            "span",
                            { staticClass: "px-4" },
                            [
                              _c("AppButton", {
                                attrs: { "custom-class": "pa-5" },
                                on: { submit: _vm.manageForm },
                                scopedSlots: _vm._u([
                                  {
                                    key: "content",
                                    fn: function () {
                                      return [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t(_vm.getButtonLabel)) +
                                            " "
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("SuccessConfirmation", {
        attrs: { title: _vm.successTitle, type: _vm.successType },
        on: {
          submit: function ($event) {
            return _vm.$router.go(-1)
          },
          cancel: _vm.toggleSuccess,
        },
        model: {
          value: _vm.showSuccess,
          callback: function ($$v) {
            _vm.showSuccess = $$v
          },
          expression: "showSuccess",
        },
      }),
      _c(
        "v-snackbar",
        {
          attrs: { color: _vm.snackbarColor, bottom: "", right: "" },
          scopedSlots: _vm._u([
            {
              key: "action",
              fn: function ({ attrs }) {
                return [
                  _c(
                    "v-btn",
                    _vm._b(
                      {
                        attrs: { color: "white", text: "" },
                        on: {
                          click: function ($event) {
                            _vm.showSnackbar = false
                          },
                        },
                      },
                      "v-btn",
                      attrs,
                      false
                    ),
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.showSnackbar,
            callback: function ($$v) {
              _vm.showSnackbar = $$v
            },
            expression: "showSnackbar",
          },
        },
        [_vm._v(" " + _vm._s(_vm.message) + " ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }